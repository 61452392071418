import React, { Suspense, useMemo } from "react";
import { BrowserRouter, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { authProtectedRoutes, publicRoutes } from "./routes";
import NonAuthLayout from "../layouts/NonAuth.jsx";
import AuthLayout from "../layouts/AuthLayout";
import Loading from "../components/general/Loading";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {

  const { search } = useLocation();
  let urlQuery = useMemo(() => {
    return new URLSearchParams(search)
  }, [search]);
  let phone = urlQuery.get("phone")?.replace(/\D/g,"");
  let groupPhone = urlQuery.get("group")?.replace(/\D/g,"")

  if(phone && phone.length <= 15 && phone.length>= 7){
    sessionStorage.setItem('urlQueryPhone', phone);
    if (groupPhone) sessionStorage.setItem('urlQueryGroupPhone', groupPhone);
  } 

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !localStorage.getItem("authUser")) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

const Routes = () => {
  return (
    <BrowserRouter>
      <React.Fragment>
        <Suspense fallback={<Loading />}>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            <Route exact path="/">
              <Redirect to="dashboard" />
            </Route>
            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={AuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
            <Route>
              <Redirect to="dashboard" />
            </Route>
          </Switch>
        </Suspense>
      </React.Fragment>
    </BrowserRouter>
  );
};

export default Routes;
