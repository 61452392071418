import { APIClient } from "../../helpers/apiClient";
import { isPublisher } from "../../helpers/checkRoles";

import IconMantra from "../../assets/images/icon-mantra2.png";

import {
  SET_TEMPLATES,
  SET_ACTIVE_SIDEBAR_TAB,
  GET_TEMPLATES,
  SEND_TEMPLATE,
  CHANGE_VIEW_TEMPLATES,
  CHANGE_USER_IN_TEMPLATES,
  SET_WA_TEMPLATES,
  SET_SORTED_TEMPLATES,
  CHANGE_EDIT_WA_TEMPLATE_ID,
} from "./constants";
import { isIOS } from "../../helpers/checkBrowser";

const apiClient = new APIClient();
const { get, create, put, remove, patch } = apiClient;

export const setTemplates = (contacts) => {
  return {
    type: SET_TEMPLATES,
    payload: contacts,
  };
};

export const getTemplates = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    if (currentGroup) {
      const { id: groupId } = currentGroup;
      const response = await get("/templates", { params: { groupId, withArchived: true } });
      const currentGroupId = getState().Group.currentGroup?.id;
      if (currentGroupId === groupId) {
        dispatch(setTemplates(response));
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const sendTemplate = (templateId) => async (dispatch, getState) => {
  const {
    currentGroup: { id: groupId },
  } = getState().Group;
  const activeContact = getState().Contact.activeContact;

  try {
    await apiClient.postToMessageApi(
      `/templates`,
      {
        contactId: activeContact.id,
        templateId,
      },
      {
        headers: { GroupId: groupId },
      }
    );
  } catch (error) {
    console.log(error);
  }
};

export const archiveTemplate = (templateId) => async (dispatch, getState) => {
  try {
    const currentGroupId = getState().Group.currentGroup?.id;
    await put(`/templates/${templateId}/archived`);
    const currentGroupIdAfterReq = getState().Group.currentGroup?.id;
    const templates = getState().Templates.templates;
    const newTemplates = templates.map((template) => {
      if (template.id === templateId) {
        return {
          ...template,
          archived: true,
        };
      }
      return template;
    });
    if (currentGroupIdAfterReq === currentGroupId) dispatch(setTemplates(newTemplates));
  } catch (error) {
    console.log(error);
  }
};

export const unarchiveTemplate = (templateId) => async (dispatch, getState) => {
  try {
    const currentGroupId = getState().Group.currentGroup?.id;
    await remove(`/templates/${templateId}/archived`);
    const currentGroupIdAfterReq = getState().Group.currentGroup?.id;

    if (currentGroupIdAfterReq === currentGroupId) {
      const templates = getState().Templates.templates;
      const newTemplates = templates.map((template) => {
        if (template.id === templateId) {
          return {
            ...template,
            archived: false,
          };
        }
        return template;
      });
      dispatch(setTemplates(newTemplates));
    }
  } catch (error) {
    console.log(error);
    if (error && error.includes("409"))
      throw Error("WATEMPLATE_NOT_APPROVED");
    else
      throw Error("Error desarchivando plantilla");
  }
};

export const changeViewTemplates = (payload) => ({
  type: CHANGE_VIEW_TEMPLATES,
  payload,
});

export const changeUserInTemplates = (payload) => ({
  type: CHANGE_USER_IN_TEMPLATES,
  payload,
});

export const setWaTemplates = (templates) => {
  return {
    type: SET_WA_TEMPLATES,
    payload: templates,
  };
};

export const getWaTemplates = () => async (dispatch, getState) => {
  try {
    const currentGroup = getState().Group.currentGroup;
    if (currentGroup) {
      const { id: groupId } = currentGroup;
      const response = await get("/watemplates", { params: { groupId } });
      const currentGroupIdAfterReq = getState().Group.currentGroup?.id;
      if (currentGroupIdAfterReq === groupId) dispatch(setWaTemplates(response));
    }
  } catch (e) {
    console.log(e);
  }
};

export const applyTemplate = (payload) => async (dispatch, getState) => {
  const {
    currentGroup: { id: groupId },
  } = getState().Group;
  const {
    name,
    type,
    text,
    example,
    category,
    buttons,
    file,
    header,
    footer,
    allowCategoryChange,
    enableLinkTracking,
    languageCode,
  } = payload;
  try {
    let template;
    if (['IMAGE', 'VIDEO', 'DOCUMENT'].includes(type)) {
      if (!file) throw Error('FILE_REQUIRED');
      const formData = new FormData();
      formData.append("media", file);
      formData.append("groupId", groupId);
      formData.append("name", name);
      formData.append("templateType", type);
      formData.append("templateText", text);
      formData.append("example", example);
      formData.append("category", category);
      formData.append("languageCode", languageCode);
      if (buttons) {
        formData.append("buttons", JSON.stringify(buttons));
      }
      if (footer)
        formData.append("footer", footer);
      if (!!allowCategoryChange)
        formData.append("allowCategoryChange", !!allowCategoryChange);
      if (!!enableLinkTracking)
        formData.append("enableLinkTracking", !!enableLinkTracking);
      template = await create("/watemplates/apply-media", formData);
    } else if (type == 'TEXT') {
      template = await create("/watemplates/apply", {
        groupId,
        name,
        templateType: type,
        templateText: text,
        example,
        category,
        buttons,
        languageCode,
        ...(header && { header }),
        ...(footer && { footer }),
        ...(allowCategoryChange && { allowCategoryChange }),
        ...(enableLinkTracking && { enableLinkTracking }),
      });
    } else {
      throw Error("Error setting type of template");
    }
    const currentGroupIdAfterReq = getState().Group.currentGroup?.id;
    const waTemplates = getState().Templates.waTemplates;
    if (template && template.id && currentGroupIdAfterReq === groupId) {
      const newWaTemplates = [template, ...waTemplates];
      dispatch(setWaTemplates(newWaTemplates));
    }
  } catch (error) {
    console.log(error);
    if (error.message === 'FILE_REQUIRED') throw Error('Archivo adjunto es requerido');
    throw Error("Error applying new template");
  }
};

export const registerTemplate = (payload) => async (dispatch, getState) => {
  const currentGroup = getState().Group.currentGroup;
  const { name, content, gsId, file } = payload;
  try {
    if (file) {
      const formData = new FormData();
      formData.append("media", file);
      const urlMedia = await create("/files", formData);
      content.media = urlMedia.url;
    }
    const template = await create("/templates", {
      groupId: currentGroup.id,
      name,
      content,
      gsId,
    });
    const currentGroupIdAfterReq = getState().Group.currentGroup?.id;
    const templates = getState().Templates.templates;
    if (template && template.id && currentGroupIdAfterReq === currentGroup.id) {
      const newTemplates = [...templates, template];
      dispatch(setTemplates(newTemplates));
    }
  } catch (error) {
    console.log(error);
    throw Error("Error registering new template");
  }
};

export const editTemplate =
  (templateId, payload) => async (dispatch, getState) => {
    try {
      const currentGroupId = getState().Group.currentGroup?.id;
      const { name, content, gsId, order, file } = payload;
      if (file) {
        const formData = new FormData();
        formData.append("media", file);
        const urlMedia = await create("/files", formData);
        content.media = urlMedia.url;
      }
      const template = await patch(`/templates/${templateId}`, {
        name,
        content: {
          text: content.text,
          type: content.type,
          media: content.media,
          ...(content.filename && { filename: content.filename }),
        },
        gsId,
        order,
      });
      const currentGroupIdAfterReq = getState().Group.currentGroup?.id;
      const templates = getState().Templates.templates;
      if (template && template.id && currentGroupIdAfterReq === currentGroupId) {
        const newTemplates = templates.map((t) =>
          t.id == template.id ? template : t
        );
        dispatch(setTemplates(newTemplates));
      }
    } catch (error) {
      console.log(error);
      throw Error("Error updating template");
    }
  };

export const deleteWaTemplate = (templateId) => async (dispatch, getState) => {
  try {
    const currentGroupId = getState().Group.currentGroup?.id;
    await remove(`/watemplates/${templateId}`);
    const currentGroupIdAfterReq = getState().Group.currentGroup?.id;
    const waTemplates = getState().Templates.waTemplates;
    if (currentGroupIdAfterReq === currentGroupId) {
      const newWaTemplates = waTemplates.filter((t) => t.id !== templateId);
      dispatch(setWaTemplates(newWaTemplates));
    }
  } catch (error) {
    console.log(error);
    throw Error("Error deleting template");
  }
};

export const updateTemplateStatus = (payload) => async (dispatch, getState) => {
  try {
    const { templateId, status, category } = payload;
    const waTemplates = getState().Templates.waTemplates;
    const foundTemplate = waTemplates.find((t) => t.id == templateId);
    if (foundTemplate) {
      const newWaTemplates = waTemplates.map((template) => {
        if (template.id === templateId) {
          return {
            ...template,
            status,
            ...(template.category != category && {
              category,
              prevCategory: template.category,
              showPrevCategory: true,
            }),
          };
        }
        return template;
      });
      dispatch(setWaTemplates(newWaTemplates));
      if (status === "approved" || status === "rejected") {
        if (isIOS()) return;
        let notification = new Notification(
          `Plantilla ${status === "approved" ? "aprobada" : "rechazada"}`,
          {
            icon: IconMantra,
            body: `Nombre: ${foundTemplate.name}`,
          }
        );
        notification.onclick = function (e) {
          e.preventDefault();
          window.focus();
          notification.close();
        };
      }
    }
  } catch (error) {
    console.log(error);
    throw Error("Error updating template status");
  }
};

export const setSortedTemplates = (contacts) => {
  return {
    type: SET_SORTED_TEMPLATES,
    payload: contacts,
  };
};

export const getSortedTemplates = () => async (dispatch, getState) => {
  const currentGroup = getState().Group.currentGroup;
  try {
    const templates = await apiClient.get(
      `/templates/bypositions?groupId=${currentGroup.id}`
    );
    const currentGroupIdAfterReq = getState().Group.currentGroup?.id;
    if (Array.isArray(templates) && templates.length > 0 && currentGroupIdAfterReq === currentGroup.id) {
      const filteredTemplates = templates.filter(
        (template) => !template.archived
      );
      dispatch(setSortedTemplates(filteredTemplates));
    }
  } catch (error) {
    console.log(error);
    throw Error("Error ordering templates");
  }
};

export const setActiveSidebarTab = (tab) => ({
  type: SET_ACTIVE_SIDEBAR_TAB,
  payload: tab,
});

export const getWaTemplatesSync = () => async (dispatch, getState) => {
  try {
    const groupId = getState().Group.currentGroup?.id;
    if (groupId) {
      const response = await get("/watemplates/sync", { params: { groupId } });
      const currentGroupIdAfterReq = getState().Group.currentGroup?.id;

      if (currentGroupIdAfterReq === groupId && Array.isArray(response) && response.length) {
        const waTemplates = getState().Templates.waTemplates;
        const newWaTemplates = [...waTemplates], approvedTemplates = [], rejectedTemplates = [];

        for (const syncedTemplate of response) {
          if (syncedTemplate.prevStatus) {
            const template = newWaTemplates.find(t => t.id == syncedTemplate.id);
            if (template) {
              template.status = syncedTemplate.status;
              template.prevStatus = syncedTemplate.prevStatus;
              if (syncedTemplate.status === 'rejected' && syncedTemplate.payload?.reason)
                template.rejectedReason = syncedTemplate.payload.reason;

              if (template.status == 'approved')
                approvedTemplates.push(template);
              else if (template.status == 'rejected')
                rejectedTemplates.push(template);
            }
          }
        }
        dispatch(setWaTemplates(newWaTemplates));
        return {
          approvedTemplates,
          rejectedTemplates,
        };
      }
      return {};
    }
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const changeEditWaTemplateId = (id, buttons) => ({
  type: CHANGE_EDIT_WA_TEMPLATE_ID,
  payload: {
    id,
    buttons,
  },
});
